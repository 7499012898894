@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,500;1,400&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
    font-family: 'Roboto', sans-serif;
}

ul {
  margin-bottom: 0 !important;
  padding-left: 0 !important;
}

li {
    list-style: none;
}

a {
    text-decoration: none;
}

.navbar {
  height: 54px;
  background-color: #008e97;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 6rem;
  position: fixed !important;
  top: 0;
  width: 100%;
}
.hamburger {
  display: none;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #fff;
}
.nav-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-item {
  margin-left: 2rem;
}

.nav-link{
  padding: 0 0 12px 0 !important;
  font-size: 1.6rem;
  font-weight: 400;
  color: #fff !important;
  border-bottom: 3px solid #008e97;
  margin-top: 10px;
}

.nav-link:hover{
  opacity: 0.7;
  border-bottom: 3px solid #fff;
}

@media (max-width: 768px) {
  .nav-menu {
      position: fixed;
      left: -100%;
      top: 5rem;
      flex-direction: column;
      background-color: #fff;
      width: 100%;
      border-radius: 10px;
      text-align: center;
      transition: 0.3s;
      box-shadow:
          0 10px 27px rgba(0, 0, 0, 0.05);
  }
  .navbar {
    padding: 1rem 1rem;
  }
  .nav-menu.active {
      left: 0;
  }

  .nav-item {
      margin: 2.5rem 0;
  }

  .hamburger {
      display: block;
      cursor: pointer;
  }

  .navbar-brand-logo {
    display: none;
  }

  .navbar-brand-logo1 {
    display: block !important;
  }

  .account {
    display: none !important;
  }

}

.navbar-brand-logo {
  width: 50px;
}
.navbar-brand-logo1 {
  width: 50px;
  visibility: hidden;
}
.round {
  width: 36px;
  height: 36px;
  margin-top: 5px;
  border-radius: 100px;
  background-color: coral;
  display: flex;
  justify-content: center;
  font-size: 20px;
  padding-top: 3px;
}
.heading {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: rgb(255, 255, 255);
  line-height: 1.4;
}