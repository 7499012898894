.ticket-nav {
  height: 54px;
  /*background-color: #008e97;*/
  width: 100vw;
}
.left-arrow {
  width: 30px;
  margin-top: 0px;
}
.daqexq {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  overflow: hidden;
  margin-bottom: 1px;
  margin-top: 2px;
  color: #fff;
}
.daqdate {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.25;
  overflow: hidden;
  margin: 0;
  color: #fff;
}

.slide-head {
  border-radius: 10px;
  /*background-color: red;*/
}
.slide-top {
  border-radius: 10px;
  /*background: rgb(155, 43, 43);*/
}
.slide-body {
  border-radius: 10px;
  border: 1px solid grey;
  border-top: 0;
}
.tick-title {
  font-size: 14px;
  color: #fff;
}
.btn-img {
  width: 280px;
  margin-bottom: 60px;
  margin-top: 40px;
}
.btn-imgios {
  width: 180px;
  margin-bottom: 60px;
  margin-top: 40px;
}
.slide-block {
  padding-top: 50px;
}
.ticket-card {
  margin-top: 3px;
}
.v2-ticket-body {
  margin-left: -116px;
}
.head-text {
  font-size: 14px;
  line-height: 30px;
  /*border-top: 1px solid rgb(240, 70, 70);*/
}
@media (max-width: 420px) {
  .btn-img {
    width: 95%;
    margin-bottom: 60px;
  }
  .btn-imgios {
    width: 140px;
    margin-bottom: 60px;
    margin-top: 40px;
  }
  .alerts {
    width: 90% !important;
  }
  .alerts-text {
    margin-top: 0px !important;
  }
}
@media (max-width: 320px) {
  .btn-img {
    width: 100%;
    margin-bottom: 60px;
  }
}
.alerts-box {
  position: absolute;
  top: 90px;
  width: 100%;
}
.alerts {
  border: 1px solid black !important;
  border-radius: 4px !important;
  padding: 16px 2%;
  width: 94%;
  background: white;
}
.alerts-text {
  padding-left: 16px;
  padding-right: 32px;
  display: table-cell;
  height: 100%;
  margin-top: 10px;
  vertical-align: middle;
  line-height: 1.29;
  font-size: 14px;
  font-weight: 600;
  color: #262626;
}
.slick-prev {
  position: absolute;
  left: 49.5%;
  bottom: -33px;
  top: auto;
  width: 16px!important;
  height: 16px!important;
  font-size: 0;
  line-height: 0;
  box-shadow: none!important;
  border: 0!important;
  cursor: pointer;
  transform: translateX(-61px) !important;
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTYgMEgwdjE2aDE2eiIvPjxwYXRoIGQ9Ik00Ljg2OSA4bDYuNjc4IDYuNjc4YS43NzQuNzc0IDAgMDEtMS4wOTQgMS4wOTVMMy4yMjcgOC41NDdhLjc3NC43NzQgMCAwMTAtMS4wOTRMMTAuNDUzLjIyN2EuNzc0Ljc3NCAwIDAxMS4wOTQgMS4wOTVMNC44NyA4eiIgZmlsbC1vcGFjaXR5PSIuNjUiIGZpbGw9IiMyNjI2MjYiIGZpbGwtcnVsZT0ibm9uemVybyIvPjwvZz48L3N2Zz4=) no-repeat 50% !important;
}
.slick-next:before, .slick-prev:before {
  content: '';
}
.slick-next {
  position: absolute;
  left: 50%;
  bottom: -33px;
  top: auto;
  width: 16px !important;
  height: 16px !important;
  font-size: 0;
  line-height: 0;
  box-shadow: none!important;
  border: 0!important;
  cursor: pointer;
  transform: translateX(43px);
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTYgMEgwdjE2aDE2eiIvPjxwYXRoIGQ9Ik0xMC45MDUgOGwtNi42NzggNi42NzhhLjc3NC43NzQgMCAwMDEuMDk1IDEuMDk1bDcuMjI1LTcuMjI2YS43NzQuNzc0IDAgMDAwLTEuMDk0TDUuMzIyLjIyN2EuNzc0Ljc3NCAwIDAwLTEuMDk1IDEuMDk1TDEwLjkwNSA4eiIgZmlsbC1vcGFjaXR5PSIuNjUiIGZpbGw9IiMyNjI2MjYiIGZpbGwtcnVsZT0ibm9uemVybyIvPjwvZz48L3N2Zz4=) no-repeat 48% !important;
}
.slick-disabled {
  opacity: 0.3 !important;
}
.btn-trans {
  font-family: inherit;
  width: 35%;
  font-weight: 650;
  font-size: 16px;
  line-height: 2.63;
  padding: 0px 14px;
  min-width: 60px;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  color: rgb(255, 255, 255);
  /*background-color: rgb(0, 142, 151);*/
  border: 1px solid transparent;
  transition: background-color 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
}
.btn-dot {
  font-family: inherit;
  font-weight: 650;
  font-size: 16px;
  line-height: 2.63;
  padding: 0px 14px;
  width: 50px;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  color: rgb(255, 255, 255);
  /*background-color: rgb(0, 142, 151);*/
  border: 1px solid transparent;
  transition: background-color 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
}
.btn-block {
  margin-top: 200px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  box-shadow: 0px 0 20px rgba(0, 0, 0, 0.8);
}
.dot-p {
  margin-top: -9px;
}
