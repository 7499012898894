.main-contains {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 84px;
}
.inner-contains {
  padding: 9px 0px;
  width: 1434px;
}

.event-contains {
  display: flex;
  flex-direction: column;
  padding: 0;
  height: fit-content;
}

.event-imgs {
  width: 452px;
}
@media (max-width: 1580px) {
  .inner-contains {
    width: 1164px !important;
  }
}
@media (max-width: 1170px) {
  .inner-contains {
    width: 964px !important;
    padding: 0 20px;
  }
  .event-contains {
    flex-direction: row;
    padding: 20px;
  }
  .event-imgs {
    width: 38%;
  }
  .event-detail {
    margin-left: 20px;
  }
  .detail-contain {
    flex-direction: column;
  }
  .foot {
    display: none;
  }
  .ticket {
    margin-top: 20px;
    margin-left: 0 !important;
  }
  .safetix {
    margin-left: 0 !important;
  }
  .safetix-table {
    margin-left: 0 !important;
  }
}
@media (max-width: 970px) {
  .inner-contains {
    width: 744px !important;
  }
}
@media (max-width: 480px) {
  .event-contains {
    flex-direction: column;
    padding: 0;
  }
  .event-imgs {
    width: 100%;
  }
  .event-detail {
    margin-left: 0;
  }
}
.round-block {
  width: 44px;
  height: 44px;
  border-radius: 100px;
  border: 1px solid rgb(197, 107, 255);
  background-color: white;
  padding: 9px 9px;
}
.ticket {
  margin-left: 50px;
  border: 1px solid rgb(197, 107, 255);
  background: rgb(249, 240, 255);
  padding: 20px;
}
.ticket-title {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: rgb(144, 78, 186);
}
.ticket-detail {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: rgb(38, 38, 38);
}
.ticket-block {
  width: -webkit-fill-available;
}
.title {
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: rgb(38, 38, 38);
  margin: 0px;
}
.safetix {
  margin-left: 50px;
  margin-top: 40px;
}
.icon {
  color: #008e97;
}
.icon-title {
  font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    text-transform: uppercase;
    color: rgb(0, 142, 151);
    margin-top: 4px;
    border-bottom: 2px solid transparent;
}
.round-icon {
  width: 25px;
  height: 25px;
  border-radius: 100px;
  border: 1px solid #008e97;
  color: #008e97;
  font-size: 30px;
}
.dot {
  margin-top: -20px;
  margin-left: 2px;
}
.safetix-table {
  margin-left: 50px;
  margin-top: 10px;
}
.evAGUW {
  margin-top: 12px;
  transform: translateY(-50%) rotate(-90deg) !important;
}
.member-titles {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.4;
}
.claimed {
  color: gray;
}
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}