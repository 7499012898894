.main-contain {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 140px;
}
.top-contain {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: fixed;
  width: 100%;
  top: 54px;
  background: white;
}
.inner-contain {
  padding: 9px 0px;
  width: 1434px;
}
.input-group-text {
  padding-left: 15px !important;
}
.btn-tran {
  font-family: inherit;
  font-weight: 650;
  font-size: 16px;
  line-height: 2.63;
  padding: 0px 14px;
  min-width: 60px;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 142, 151);
  border: 1px solid transparent;
  transition: background-color 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
}
.search-input {
    height: 36px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 2px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(153, 153, 153);
    padding: 20px !important;
    line-height: normal;
    font-size: 18px !important;
    color: rgb(38, 38, 38);
}
.search-icon {
  height: 42px;
}
@media (max-width: 1580px) {
  .inner-contain {
    width: 1164px !important;
  }
}
@media (max-width: 1170px) {
  .inner-contain {
    width: 964px !important;
  }
}
@media (max-width: 970px) {
  .inner-contain {
    width: 744px !important;
  }
  .description {
    flex-direction: column !important;
  }
  .foot {
    display: none !important;
  }
}
@media (max-width: 770px) {
  .event-contain {
    flex-direction: column;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .top-contain {
    padding: 0 10px;
  }
  .main-contain {
    padding: 0 10px;
  }
  .event-img {
    width: 100% !important;
  }
  .description {
    padding-left: 10px !important;
  }
  .btn-select {
    width: 99% !important;
  }
}
.event-img {
  width: 336px;
}
.btn-select {
  border: 1px solid rgb(0, 142, 151);
  color: rgb(0, 142, 151) !important;
  text-align: center;
  text-decoration: none;
  border-radius: 2px;
  padding: 7px;
  font-size: 13px;
  font-weight: 600;
  transition: background-color .3s cubic-bezier(.455,.03,.515,.955);
  min-width: 120px;
}
.btn-select:hover {
  background: rgb(217, 238, 239);
}
.event-contain {
  padding: 20px;
}
.member-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
}
.description {
  padding-left: 42px;
}
.member-date {
  font-size: 14px;
  color: #262626;
  line-height: 17px;
}
.foot-fdesc {
  font-size: 14px;
  font-weight: 400;
  padding: 0;
}
.foot-sdesc {
  font-size: 14px;
  font-weight: 400;
  padding: 0;
}