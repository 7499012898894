@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,500;1,400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ticket-nav {
  height: 54px;
  background-color: #008e97;
  width: 100vw;
}
.left-arrow {
  width: 30px;
  margin-top: 0px;
}
.daqexq {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  overflow: hidden;
  margin-bottom: 1px;
  margin-top: 2px;
  color: #fff;
}
.daqdate {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.25;
  overflow: hidden;
  margin: 0;
  color: #fff;
}

.slide-head {
  border-radius: 10px;
  background-color: rgba(0,0,0,.3);
}
.slide-top {
  border-radius: 10px;
  /*background: rgb(155, 43, 43);*/
  background: rgba(0,0,0,.3);
}
.slide-body {
  border-radius: 10px;
  border: 1px solid grey;
  border-top: 0;
}
.tick-title {
  font-size: 14px;
  color: #fff;
}
.btn-img {
  width: 280px;
  margin-bottom: 60px;
  margin-top: 40px;
}
.btn-imgios {
  width: 180px;
  margin-bottom: 60px;
  margin-top: 40px;
}
.slide-block {
  padding-top: 50px;
}
.ticket-card {
  margin-top: 3px;
}
.v2-ticket-body {
  margin-left: -116px;
}
.head-text {
  font-size: 14px;
  line-height: 30px;
  border-top: 1px solid rgba(0,0,0,.3);
}
@media (max-width: 420px) {
  .btn-img {
    width: 95%;
    margin-bottom: 60px;
  }
  .btn-imgios {
    width: 140px;
    margin-bottom: 60px;
    margin-top: 40px;
  }
  .alerts {
    width: 90% !important;
  }
  .alerts-text {
    margin-top: 0px !important;
  }
}
@media (max-width: 320px) {
  .btn-img {
    width: 100%;
    margin-bottom: 60px;
  }
}
.alerts-box {
  position: absolute;
  top: 90px;
  width: 100%;
}
.alerts {
  border: 1px solid black !important;
  border-radius: 4px !important;
  padding: 16px 2%;
  width: 94%;
  background: white;
}
.alerts-text {
  padding-left: 16px;
  padding-right: 32px;
  display: table-cell;
  height: 100%;
  margin-top: 10px;
  vertical-align: middle;
  line-height: 1.29;
  font-size: 14px;
  font-weight: 600;
  color: #262626;
}
.slick-prev {
  position: absolute;
  left: 49.5%;
  bottom: -33px;
  top: auto;
  width: 16px!important;
  height: 16px!important;
  font-size: 0;
  line-height: 0;
  box-shadow: none!important;
  border: 0!important;
  cursor: pointer;
  transform: translateX(-61px) !important;
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTYgMEgwdjE2aDE2eiIvPjxwYXRoIGQ9Ik00Ljg2OSA4bDYuNjc4IDYuNjc4YS43NzQuNzc0IDAgMDEtMS4wOTQgMS4wOTVMMy4yMjcgOC41NDdhLjc3NC43NzQgMCAwMTAtMS4wOTRMMTAuNDUzLjIyN2EuNzc0Ljc3NCAwIDAxMS4wOTQgMS4wOTVMNC44NyA4eiIgZmlsbC1vcGFjaXR5PSIuNjUiIGZpbGw9IiMyNjI2MjYiIGZpbGwtcnVsZT0ibm9uemVybyIvPjwvZz48L3N2Zz4=) no-repeat 50% !important;
}
.slick-next:before, .slick-prev:before {
  content: '';
}
.slick-next {
  position: absolute;
  left: 50%;
  bottom: -33px;
  top: auto;
  width: 16px !important;
  height: 16px !important;
  font-size: 0;
  line-height: 0;
  box-shadow: none!important;
  border: 0!important;
  cursor: pointer;
  transform: translateX(43px);
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTYgMEgwdjE2aDE2eiIvPjxwYXRoIGQ9Ik0xMC45MDUgOGwtNi42NzggNi42NzhhLjc3NC43NzQgMCAwMDEuMDk1IDEuMDk1bDcuMjI1LTcuMjI2YS43NzQuNzc0IDAgMDAwLTEuMDk0TDUuMzIyLjIyN2EuNzc0Ljc3NCAwIDAwLTEuMDk1IDEuMDk1TDEwLjkwNSA4eiIgZmlsbC1vcGFjaXR5PSIuNjUiIGZpbGw9IiMyNjI2MjYiIGZpbGwtcnVsZT0ibm9uemVybyIvPjwvZz48L3N2Zz4=) no-repeat 48% !important;
}
.slick-disabled {
  opacity: 0.3 !important;
}
.btn-trans {
  font-family: inherit;
  width: 35%;
  font-weight: 650;
  font-size: 16px;
  line-height: 2.63;
  padding: 0px 14px;
  min-width: 60px;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  color: rgb(255, 255, 255);
  /*background-color: rgb(0, 142, 151);*/
  border: 1px solid transparent;
  transition: background-color 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
}
.btn-dot {
  font-family: inherit;
  font-weight: 650;
  font-size: 16px;
  line-height: 2.63;
  padding: 0px 14px;
  width: 50px;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  color: rgb(255, 255, 255);
  /*background-color: rgb(0, 142, 151);*/
  border: 1px solid transparent;
  transition: background-color 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
}
.btn-block {
  margin-top: 200px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  box-shadow: 0px 0 20px rgba(0, 0, 0, 0.8);
}
.dot-p {
  margin-top: -9px;
}

.main-contain {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 140px;
}
.top-contain {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: fixed;
  width: 100%;
  top: 54px;
  background: white;
}
.inner-contain {
  padding: 9px 0px;
  width: 1434px;
}
.input-group-text {
  padding-left: 15px !important;
}
.btn-tran {
  font-family: inherit;
  font-weight: 650;
  font-size: 16px;
  line-height: 2.63;
  padding: 0px 14px;
  min-width: 60px;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 142, 151);
  border: 1px solid transparent;
  transition: background-color 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
}
.search-input {
    height: 36px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 2px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(153, 153, 153);
    padding: 20px !important;
    line-height: normal;
    font-size: 18px !important;
    color: rgb(38, 38, 38);
}
.search-icon {
  height: 42px;
}
@media (max-width: 1580px) {
  .inner-contain {
    width: 1164px !important;
  }
}
@media (max-width: 1170px) {
  .inner-contain {
    width: 964px !important;
  }
}
@media (max-width: 970px) {
  .inner-contain {
    width: 744px !important;
  }
  .description {
    flex-direction: column !important;
  }
  .foot {
    display: none !important;
  }
}
@media (max-width: 770px) {
  .event-contain {
    flex-direction: column;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .top-contain {
    padding: 0 10px;
  }
  .main-contain {
    padding: 0 10px;
  }
  .event-img {
    width: 100% !important;
  }
  .description {
    padding-left: 10px !important;
  }
  .btn-select {
    width: 99% !important;
  }
}
.event-img {
  width: 336px;
}
.btn-select {
  border: 1px solid rgb(0, 142, 151);
  color: rgb(0, 142, 151) !important;
  text-align: center;
  text-decoration: none;
  border-radius: 2px;
  padding: 7px;
  font-size: 13px;
  font-weight: 600;
  transition: background-color .3s cubic-bezier(.455,.03,.515,.955);
  min-width: 120px;
}
.btn-select:hover {
  background: rgb(217, 238, 239);
}
.event-contain {
  padding: 20px;
}
.member-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
}
.description {
  padding-left: 42px;
}
.member-date {
  font-size: 14px;
  color: #262626;
  line-height: 17px;
}
.foot-fdesc {
  font-size: 14px;
  font-weight: 400;
  padding: 0;
}
.foot-sdesc {
  font-size: 14px;
  font-weight: 400;
  padding: 0;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
    font-family: 'Roboto', sans-serif;
}

ul {
  margin-bottom: 0 !important;
  padding-left: 0 !important;
}

li {
    list-style: none;
}

a {
    text-decoration: none;
}

.navbar {
  height: 54px;
  background-color: #008e97;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 6rem;
  position: fixed !important;
  top: 0;
  width: 100%;
}
.hamburger {
  display: none;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  transition: all 0.3s ease-in-out;
  background-color: #fff;
}
.nav-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-item {
  margin-left: 2rem;
}

.nav-link{
  padding: 0 0 12px 0 !important;
  font-size: 1.6rem;
  font-weight: 400;
  color: #fff !important;
  border-bottom: 3px solid #008e97;
  margin-top: 10px;
}

.nav-link:hover{
  opacity: 0.7;
  border-bottom: 3px solid #fff;
}

@media (max-width: 768px) {
  .nav-menu {
      position: fixed;
      left: -100%;
      top: 5rem;
      flex-direction: column;
      background-color: #fff;
      width: 100%;
      border-radius: 10px;
      text-align: center;
      transition: 0.3s;
      box-shadow:
          0 10px 27px rgba(0, 0, 0, 0.05);
  }
  .navbar {
    padding: 1rem 1rem;
  }
  .nav-menu.active {
      left: 0;
  }

  .nav-item {
      margin: 2.5rem 0;
  }

  .hamburger {
      display: block;
      cursor: pointer;
  }

  .navbar-brand-logo {
    display: none;
  }

  .navbar-brand-logo1 {
    display: block !important;
  }

  .account {
    display: none !important;
  }

}

.navbar-brand-logo {
  width: 50px;
}
.navbar-brand-logo1 {
  width: 50px;
  visibility: hidden;
}
.round {
  width: 36px;
  height: 36px;
  margin-top: 5px;
  border-radius: 100px;
  background-color: coral;
  display: flex;
  justify-content: center;
  font-size: 20px;
  padding-top: 3px;
}
.heading {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: rgb(255, 255, 255);
  line-height: 1.4;
}
.main-contains {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 84px;
}
.inner-contains {
  padding: 9px 0px;
  width: 1434px;
}

.event-contains {
  display: flex;
  flex-direction: column;
  padding: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.event-imgs {
  width: 452px;
}
@media (max-width: 1580px) {
  .inner-contains {
    width: 1164px !important;
  }
}
@media (max-width: 1170px) {
  .inner-contains {
    width: 964px !important;
    padding: 0 20px;
  }
  .event-contains {
    flex-direction: row;
    padding: 20px;
  }
  .event-imgs {
    width: 38%;
  }
  .event-detail {
    margin-left: 20px;
  }
  .detail-contain {
    flex-direction: column;
  }
  .foot {
    display: none;
  }
  .ticket {
    margin-top: 20px;
    margin-left: 0 !important;
  }
  .safetix {
    margin-left: 0 !important;
  }
  .safetix-table {
    margin-left: 0 !important;
  }
}
@media (max-width: 970px) {
  .inner-contains {
    width: 744px !important;
  }
}
@media (max-width: 480px) {
  .event-contains {
    flex-direction: column;
    padding: 0;
  }
  .event-imgs {
    width: 100%;
  }
  .event-detail {
    margin-left: 0;
  }
}
.round-block {
  width: 44px;
  height: 44px;
  border-radius: 100px;
  border: 1px solid rgb(197, 107, 255);
  background-color: white;
  padding: 9px 9px;
}
.ticket {
  margin-left: 50px;
  border: 1px solid rgb(197, 107, 255);
  background: rgb(249, 240, 255);
  padding: 20px;
}
.ticket-title {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: rgb(144, 78, 186);
}
.ticket-detail {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: rgb(38, 38, 38);
}
.ticket-block {
  width: -webkit-fill-available;
}
.title {
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: rgb(38, 38, 38);
  margin: 0px;
}
.safetix {
  margin-left: 50px;
  margin-top: 40px;
}
.icon {
  color: #008e97;
}
.icon-title {
  font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    text-transform: uppercase;
    color: rgb(0, 142, 151);
    margin-top: 4px;
    border-bottom: 2px solid transparent;
}
.round-icon {
  width: 25px;
  height: 25px;
  border-radius: 100px;
  border: 1px solid #008e97;
  color: #008e97;
  font-size: 30px;
}
.dot {
  margin-top: -20px;
  margin-left: 2px;
}
.safetix-table {
  margin-left: 50px;
  margin-top: 10px;
}
.evAGUW {
  margin-top: 12px;
  transform: translateY(-50%) rotate(-90deg) !important;
}
.member-titles {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.4;
}
.claimed {
  color: gray;
}
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}
.ticket-nav {
  height: 54px;
  /*background-color: #008e97;*/
  width: 100vw;
}
.left-arrow {
  width: 30px;
  margin-top: 0px;
}
.daqexq {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  overflow: hidden;
  margin-bottom: 1px;
  margin-top: 2px;
  color: #fff;
}
.daqdate {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.25;
  overflow: hidden;
  margin: 0;
  color: #fff;
}

.slide-head {
  border-radius: 10px;
  /*background-color: red;*/
}
.slide-top {
  border-radius: 10px;
  /*background: rgb(155, 43, 43);*/
}
.slide-body {
  border-radius: 10px;
  border: 1px solid grey;
  border-top: 0;
}
.tick-title {
  font-size: 14px;
  color: #fff;
}
.btn-img {
  width: 280px;
  margin-bottom: 60px;
  margin-top: 40px;
}
.btn-imgios {
  width: 180px;
  margin-bottom: 60px;
  margin-top: 40px;
}
.slide-block {
  padding-top: 50px;
}
.ticket-card {
  margin-top: 3px;
}
.v2-ticket-body {
  margin-left: -116px;
}
.head-text {
  font-size: 14px;
  line-height: 30px;
  /*border-top: 1px solid rgb(240, 70, 70);*/
}
@media (max-width: 420px) {
  .btn-img {
    width: 95%;
    margin-bottom: 60px;
  }
  .btn-imgios {
    width: 140px;
    margin-bottom: 60px;
    margin-top: 40px;
  }
  .alerts {
    width: 90% !important;
  }
  .alerts-text {
    margin-top: 0px !important;
  }
}
@media (max-width: 320px) {
  .btn-img {
    width: 100%;
    margin-bottom: 60px;
  }
}
.alerts-box {
  position: absolute;
  top: 90px;
  width: 100%;
}
.alerts {
  border: 1px solid black !important;
  border-radius: 4px !important;
  padding: 16px 2%;
  width: 94%;
  background: white;
}
.alerts-text {
  padding-left: 16px;
  padding-right: 32px;
  display: table-cell;
  height: 100%;
  margin-top: 10px;
  vertical-align: middle;
  line-height: 1.29;
  font-size: 14px;
  font-weight: 600;
  color: #262626;
}
.slick-prev {
  position: absolute;
  left: 49.5%;
  bottom: -33px;
  top: auto;
  width: 16px!important;
  height: 16px!important;
  font-size: 0;
  line-height: 0;
  box-shadow: none!important;
  border: 0!important;
  cursor: pointer;
  transform: translateX(-61px) !important;
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTYgMEgwdjE2aDE2eiIvPjxwYXRoIGQ9Ik00Ljg2OSA4bDYuNjc4IDYuNjc4YS43NzQuNzc0IDAgMDEtMS4wOTQgMS4wOTVMMy4yMjcgOC41NDdhLjc3NC43NzQgMCAwMTAtMS4wOTRMMTAuNDUzLjIyN2EuNzc0Ljc3NCAwIDAxMS4wOTQgMS4wOTVMNC44NyA4eiIgZmlsbC1vcGFjaXR5PSIuNjUiIGZpbGw9IiMyNjI2MjYiIGZpbGwtcnVsZT0ibm9uemVybyIvPjwvZz48L3N2Zz4=) no-repeat 50% !important;
}
.slick-next:before, .slick-prev:before {
  content: '';
}
.slick-next {
  position: absolute;
  left: 50%;
  bottom: -33px;
  top: auto;
  width: 16px !important;
  height: 16px !important;
  font-size: 0;
  line-height: 0;
  box-shadow: none!important;
  border: 0!important;
  cursor: pointer;
  transform: translateX(43px);
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTYgMEgwdjE2aDE2eiIvPjxwYXRoIGQ9Ik0xMC45MDUgOGwtNi42NzggNi42NzhhLjc3NC43NzQgMCAwMDEuMDk1IDEuMDk1bDcuMjI1LTcuMjI2YS43NzQuNzc0IDAgMDAwLTEuMDk0TDUuMzIyLjIyN2EuNzc0Ljc3NCAwIDAwLTEuMDk1IDEuMDk1TDEwLjkwNSA4eiIgZmlsbC1vcGFjaXR5PSIuNjUiIGZpbGw9IiMyNjI2MjYiIGZpbGwtcnVsZT0ibm9uemVybyIvPjwvZz48L3N2Zz4=) no-repeat 48% !important;
}
.slick-disabled {
  opacity: 0.3 !important;
}
.btn-trans {
  font-family: inherit;
  width: 35%;
  font-weight: 650;
  font-size: 16px;
  line-height: 2.63;
  padding: 0px 14px;
  min-width: 60px;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  color: rgb(255, 255, 255);
  /*background-color: rgb(0, 142, 151);*/
  border: 1px solid transparent;
  transition: background-color 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
}
.btn-dot {
  font-family: inherit;
  font-weight: 650;
  font-size: 16px;
  line-height: 2.63;
  padding: 0px 14px;
  width: 50px;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  color: rgb(255, 255, 255);
  /*background-color: rgb(0, 142, 151);*/
  border: 1px solid transparent;
  transition: background-color 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
}
.btn-block {
  margin-top: 200px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  box-shadow: 0px 0 20px rgba(0, 0, 0, 0.8);
}
.dot-p {
  margin-top: -9px;
}

